<template>
  <div>
    <div
      class="mt-5 d-flex align-center justify-center"
      v-if="loadingGetCities"
    >
      <v-progress-circular color="grey" indeterminate class="text-center" />
      <div class="grey--text ml-2 font-weight-bold">載入縣市...</div>
    </div>
    <v-select
      v-model="cityId"
      :items="cities"
      label="選擇縣市"
      hide-details
      class="mb-4"
      solo
      item-text="name"
      item-value="id"
      v-else
    ></v-select>
    <v-row class="font-weight-bold" v-if="cityId">
      <v-col cols="12" md="2">
        <v-card class="font-weight-bold">
          <div class="text-center pt-5">IPC 連線率</div>
          <div class="text-caption text-center grey--text">
            每 15 分鐘更新一次
          </div>
          <div class="px-10 pb-10 pt-5 d-flex justify-center align-center">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="12" class="text-center">
                <v-progress-circular
                  :rotate="270"
                  :size="120"
                  :width="15"
                  :value="connectedIPCRate"
                  color="primary"
                  class="text-h5"
                >
                  {{ connectedIPCRate }}%
                </v-progress-circular>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="10"
                :class="{ 'mt-3': !$vuetify.breakpoint.xs }"
                class="accent--text"
              >
                <div class="d-flex justify-space-between">
                  <div>上線 IPC 數</div>
                  <div>{{ connectedSchoolsLength }}</div>
                </div>
                <div class="mt-2 d-flex justify-space-between">
                  <div>學校總數</div>
                  <div>{{ schools.length }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="font-weight-bold mt-5">
          <div class="text-center pt-5">裝置連線率</div>
          <div class="text-caption text-center grey--text">
            含總表、分表、dongle、卡機
          </div>
          <div class="text-caption text-center grey--text">
            依最後更新時間判斷
          </div>
          <div class="px-10 pb-10 pt-5 d-flex justify-center align-center">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="12" class="text-center">
                <v-progress-circular
                  :rotate="270"
                  :size="120"
                  :width="15"
                  :value="connectedDeviceRate"
                  color="primary"
                  class="text-h5"
                >
                  {{ connectedDeviceRate }}%
                </v-progress-circular>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="10"
                :class="{ 'mt-3': !$vuetify.breakpoint.xs }"
                class="accent--text"
              >
                <div class="d-flex justify-space-between">
                  <div>連線中</div>
                  <div>{{ connectedDeviceAmount }}</div>
                </div>
                <div class="mt-2 d-flex justify-space-between">
                  <div>總裝置數</div>
                  <div>{{ allDeviceAmount }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="font-weight-bold mt-5">
          <div class="text-center pt-5">裝置測通率</div>
          <div class="text-caption text-center grey--text">
            含總表、分表、dongle、卡機
          </div>
          <div class="text-caption text-center grey--text">
            依最後更新時間判斷
          </div>
          <div class="px-10 pb-10 pt-5 d-flex justify-center align-center">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="12" class="text-center">
                <v-progress-circular
                  :rotate="270"
                  :size="120"
                  :width="15"
                  :value="hasConnectedDeviceRate"
                  color="primary"
                  class="text-h5"
                >
                  {{ hasConnectedDeviceRate }}%
                </v-progress-circular>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="10"
                :class="{ 'mt-3': !$vuetify.breakpoint.xs }"
                class="accent--text"
              >
                <div class="d-flex justify-space-between">
                  <div>曾經連線數</div>
                  <div>{{ hasConnectedDeviceAmount }}</div>
                </div>
                <div class="text-caption grey--text">
                  連線中 + 更新時間超過20分鐘的數量
                </div>
                <div class="mt-2 d-flex justify-space-between">
                  <div>總裝置數</div>
                  <div>{{ allDeviceAmount }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-card class="pa-5" width="100%">
          <div class="d-flex">
            <div class="text-h6 accent--text font-weight-bold">學校列表</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWords"
              placeholder="搜尋名稱"
              filled
              rounded
              dense
              append-icon="mdi-magnify"
              hide-details=""
            ></v-text-field>
          </div>
          <div class="d-flex my-3">
            <v-spacer />
            <v-btn
              color="light-blue darken-4"
              :loading="loadingSyncAll"
              class="syncBtn white--text"
              :class="{ loadingSize: loadingSyncAll }"
              @click="syncAll"
              :disabled="!schools.length || loadingGetCityData"
            >
              一鍵表位同步
              <v-icon right small>mdi-cursor-default-click</v-icon>
            </v-btn>
            <v-btn
              color="light-blue darken-4"
              class="ml-4 syncBtn white--text"
              :class="{ loadingSize: loadingSendAll }"
              :loading="loadingSendAll"
              @click="sendAll"
              :disabled="!schools.length || loadingGetCityData"
            >
              一鍵派送群組
              <v-icon right>mdi-gesture-double-tap</v-icon>
            </v-btn>
          </div>
          <v-data-table
            :headers="headers"
            :items="filterSchools"
            hide-default-footer
            :items-per-page="-1"
            :loading="loadingGetCityData"
            loading-text="載入中..."
            no-data-text="( 無資料 )"
            @click:row="toSchoolDevices"
          >
            <template v-slot:[`item.IPCisConnected`]="{ item }">
              <v-icon
                :color="item.IPCisConnected ? 'success' : 'error'"
                class="ml-3"
                >mdi-circle-slice-8</v-icon
              >
            </template>
            <template v-slot:[`item.updateTime`]="{ item }">
              {{ moment(item.updateTime).format('YYYY/MM/DD h:mm:ss') }}
            </template>
            <template v-slot:[`item.sync`]="{ item, index }">
              <v-btn
                color="primary"
                @click.stop.prevent="syncData(index)"
                :disabled="loadingSync[index]"
                icon
                class="ml-2"
              >
                <v-progress-circular
                  v-if="loadingSync[index]"
                  indeterminate
                  size="15"
                  width="2"
                  color="grey"
                />
                <template v-else> <v-icon>mdi-sync</v-icon> </template>
              </v-btn>
            </template>
            <template v-slot:[`item.send`]="{ item, index }">
              <v-btn
                color="primary"
                @click.stop.prevent="sendData(index)"
                :disabled="loadingSend[index]"
                icon
                class="ml-2"
              >
                <v-progress-circular
                  v-if="loadingSend[index]"
                  indeterminate
                  size="15"
                  width="2"
                  color="grey"
                />
                <template v-else> <v-icon small>mdi-send</v-icon> </template>
              </v-btn>
            </template>
            <!-- <template v-slot:[`item.link`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mr-2"
                    @click="toSchool(item)"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!item.id"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>前往設定管理</span>
              </v-tooltip>
            </template> -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import City from '@/api/admin/City'
import School from '@/api/admin/School'
import Develop from '@/api/develop/Develop'
import IPC from '@/api/ems/IPC'
import Swal from 'sweetalert2'
import Device from '@/api/ems/Device'
import Group from '@/api/ems/Group'

export default {
  props: {
    cityId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      cities: [],
      schools: [],
      loadingGetCities: false,
      loadingGetCityData: false,
      loadingSync: [],
      loadingSend: [],
      loadingSyncAll: false,
      loadingSendAll: false,
      headers: [
        { text: '名稱', value: 'name' },
        {
          text: '連線中',
          value: 'connectedDevices',
          width: '100',
          align: 'center'
        },
        {
          text: '尚未連線',
          value: 'disconnectedDevices',
          width: '100',
          align: 'center'
        },
        {
          text: '最後更新時間超過 20 分鐘',
          value: 'pendingDevices',
          width: '200',
          align: 'center'
        },
        {
          text: 'IPC 連線狀態',
          value: 'IPCisConnected',
          width: '140'
        },
        {
          text: 'IPC 狀態更新時間',
          value: 'updateTime',
          width: '200'
        },
        { text: '表位同步', value: 'sync', width: '100', sortable: false },
        { text: '派送群組', value: 'send', width: '100', sortable: false }
        // { text: '', value: 'link', width: '50', sortable: false }
      ],
      searchWords: ''
    }
  },
  computed: {
    connectedSchoolsLength() {
      return this.schools.filter((school) => school.isConnected).length
    },
    connectedIPCRate() {
      return this.schools.length
        ? parseInt((this.connectedSchoolsLength / this.schools.length) * 100)
        : 0
    },
    connectedDeviceAmount() {
      let countConnected = 0
      this.schools.forEach((school) => {
        countConnected += school.connectedDevices
      })
      return countConnected
    },
    hasConnectedDeviceAmount() {
      let countConnected = 0
      this.schools.forEach((school) => {
        countConnected += school.connectedDevices
        countConnected += school.pendingDevices
      })
      return countConnected
    },
    allDeviceAmount() {
      let countAllDevices = 0
      this.schools.forEach((school) => {
        countAllDevices +=
          school.connectedDevices +
          school.disconnectedDevices +
          school.pendingDevices
      })
      return countAllDevices
    },
    connectedDeviceRate() {
      return parseInt((this.connectedDeviceAmount / this.allDeviceAmount) * 100)
    },
    hasConnectedDeviceRate() {
      return parseInt(
        (this.hasConnectedDeviceAmount / this.allDeviceAmount) * 100
      )
    },
    filterSchools() {
      if (this.loadingGetCityData) return []
      let filterSchools = this.schools
        .filter((school) => school.name.includes(this.searchWords))
        .map(({ isConnected, ...rest }) => ({
          ...rest,
          IPCisConnected: isConnected
        }))
      return filterSchools
    }
  },
  watch: {
    cityId(value) {
      this.$router.push({ name: 'SyncCity', params: { cityId: value } })
      this.init()
    },
    filterSchools(value) {
      this.loadingSync = new Array(value.length).fill(false)
      this.loadingSend = new Array(value.length).fill(false)
    }
  },
  async mounted() {
    await this.getCities()
    if (this.cityId) this.init()
  },
  methods: {
    async init() {
      this.loadingGetCityData = true
      await this.getCityIPC()
      await this.getSchoolsDeviceStatus()
      this.loadingGetCityData = false
      this.judgeStatusAmount()
    },
    async getCities() {
      try {
        this.loadingGetCities = true
        const { data } = await City.list()
        this.cities = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingGetCities = false
        if (this.cities.length === 1) {
          this.cityId = this.cities[0].id
        }
      }
    },
    async getCityIPC() {
      try {
        const { data } = await IPC.getCityIPC(this.cityId)
        this.schools = data
      } catch (error) {
        console.error(error)
      }
    },
    async getSchoolsDeviceStatus() {
      for (let index = 0; index < this.schools.length; index++) {
        try {
          const { data } = await Device.getSchoolDeviceStatus(
            this.schools[index].id
          )
          this.schools[index] = { ...data, ...this.schools[index] }
        } catch (error) {
          console.error(error)
        }
      }
    },
    judgeStatusAmount() {
      this.schools.forEach((school, index) => {
        let countConnected = 0
        let countDisconnected = 0
        let countPending = 0
        const current = new Date()
        school.devices?.forEach((device) => {
          if (!device.updateTs) countDisconnected += 1
          else {
            const updateTime = new Date(device.updateTs)
            if (current - updateTime < 20 * 60 * 1000) countConnected += 1
            else countPending += 1
          }
        })
        school.child?.forEach((classroom) => {
          classroom?.devices?.forEach((device) => {
            if (!device.updateTs) countDisconnected += 1
            else {
              const updateTime = new Date(device.updateTs)
              if (current - updateTime < 20 * 60 * 1000) countConnected += 1
              else countPending += 1
            }
          })
        })
        this.$set(this.schools, index, {
          ...this.schools[index],
          connectedDevices: countConnected,
          disconnectedDevices: countDisconnected,
          pendingDevices: countPending
        })
      })
    },
    async syncIPC(schoolSerialNumber) {
      try {
        await Develop.syncApp(schoolSerialNumber)
        await IPC.syncIPC(schoolSerialNumber)
      } catch (error) {
        throw Error(error.response.data.message)
      }
    },
    async syncData(index) {
      this.loadingSync.splice(index, 1, true)
      try {
        await this.syncIPC(this.filterSchools[index].serialNumber)
      } catch (errorMsg) {
        Swal.fire({
          text: errorMsg || `${this.filterSchools[index].name}表位同步失敗`,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          customClass: {
            popup: 'format-swal'
          }
        })
      }
      this.loadingSync.splice(index, 1, false)
    },
    async sendGroupData(schoolId) {
      try {
        await Group.sendDataToIPC(schoolId)
      } catch (error) {
        throw Error(error.response.data.message)
      }
    },
    async sendData(index) {
      this.loadingSend.splice(index, 1, true)
      try {
        await this.sendGroupData(this.filterSchools[index].id)
      } catch (errorMsg) {
        Swal.fire({
          text: errorMsg || `${this.filterSchools[index].name}派送群組失敗`,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          customClass: {
            popup: 'format-swal'
          }
        })
      }
      this.loadingSend.splice(index, 1, false)
    },
    toSchool(school) {
      if (school.id)
        this.$router.push({ name: 'Damaged', params: { tagId: school.id } })
    },
    toSchoolDevices(val) {
      if (val.id)
        this.$router.push({
          name: 'SchoolDeviceStatus',
          params: { schoolId: val.id }
        })
    },
    async syncAll() {
      this.loadingSyncAll = true
      let failedSchools = ''
      for (let i = 0; i < this.schools.length; i++) {
        try {
          await this.syncIPC(this.schools[i].serialNumber)
        } catch {
          failedSchools = failedSchools + this.schools[i].name + '<br />'
        }
      }
      this.loadingSyncAll = false
      if (failedSchools.length) {
        Swal.fire({
          title: '以下學校表位同步失敗',
          html: failedSchools,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          customClass: {
            popup: 'format-swal'
          }
        })
      } else {
        Swal.fire({
          title: '全部學校表位同步成功',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          customClass: {
            popup: 'format-swal'
          }
        })
      }
    },
    async sendAll() {
      this.loadingSendAll = true
      let failedSchools = ''
      for (let i = 0; i < this.schools.length; i++) {
        try {
          await this.sendGroupData(this.schools[i].id)
        } catch {
          failedSchools = failedSchools + this.schools[i].name + '<br />'
        }
      }
      this.loadingSendAll = false
      if (failedSchools.length) {
        Swal.fire({
          title: '以下學校派送群組失敗',
          html: failedSchools,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          customClass: {
            popup: 'format-swal'
          }
        })
      } else {
        Swal.fire({
          title: '全部學校派送群組成功',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          customClass: {
            popup: 'format-swal'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .syncBtn {
    transition: min-width 0.5s, border-radius 0.5s;
    position: relative;
    min-width: 10rem !important;
    .v-btn__content {
      position: absolute;
    }
  }
  .loadingSize {
    min-width: 0 !important;
    width: 36px;
    border-radius: 50%;
  }
}
</style>

<style lang="scss">
.format-swal {
  word-break: break-all !important;
}
</style>
