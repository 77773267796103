import settings from '@/settings'

/**
 * 開發測試
 */
class Develop {
  /**
   * 同步施工 APP 資料
   */
  syncApp(schoolSerialNumber) {
    return axios.get(
      settings.api.fullPath +
        `/develop/sync_construction_raw/school/${schoolSerialNumber}`
    )
  }
}

var develop = new Develop()
export default develop
